import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import './../assets/css/contact.css'

import envelope from './../assets/img/envelope.svg'
import onlyLogoHome from './../assets/img/only_logo_home.svg'
import subWhiteStroke from './../assets/img/subtitle_white_stroke.svg'
import whiteArrow from './../assets/img/white_arrow.svg'
import joinPhone from './../assets/img/join_phone.svg'

const Contact = () => {
  const [dataForm, setDataForm] = useState({
    nom: '',
    nomDeLetablissement: '',
    fonction: '',
    telephone: '',
    email: '',
    message: ''
  })

  const [dataFormErr, setDataFormErr] = useState({
    nom: false,
    nomDeLetablissement: false,
    fonction: false,
    telephone: false,
    email: false,
    message: false,
    checked: false
  })

  const [checked, setChecked] = useState(false)

  const [success, setSuccess] = useState(false)

  const handleChange = e => {
    const { value, name } = e.target
    console.log(dataForm)
    setDataForm({
      ...dataForm,
      [name]: value
    })
  }

  const validateData = () => {
    const { nom, email, message, nomDeLetablissement, telephone, fonction } = dataForm

    function validateEmail (mail) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(mail).toLowerCase())
    }

    setDataFormErr({
      nom: nom.length === 0,
      email: !validateEmail(email),
      message: message.length === 0,
      nomDeLetablissement: nomDeLetablissement.length === 0,
      telephone: telephone.length === 0,
      fonction: fonction.length === 0,
      checked: !checked
    })
    console.log(dataFormErr)

    if (nom.length === 0) return false
    else if (!validateEmail(email)) return false
    else if (message.length === 0) return false
    else if (nomDeLetablissement.length === 0) return false
    else if (telephone.length === 0) return false
    else if (fonction.length === 0) return false
    else if (!checked) return false
    else return true
  }

  const sendMsg = e => {
    const isValid = validateData()
    console.log('send')
    e.preventDefault()

    const { nom, email, message, nomDeLetablissement, telephone, fonction } = dataForm

    console.log(isValid)

    if (isValid) {
      const payload = { nom, email, message, telephone, fonction, nomDeLetablissement }
      console.log('good')
      fetch('https://usebasin.com/f/38b00e5b1da8', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(res => {
          // console.log('sended', res.ok)
          if (res.ok) {
            setSuccess(true)
            setDataForm({
              nom: '',
              nomDeLetablissement: '',
              fonction: '',
              telephone: '',
              email: '',
              message: ''
            })
            setChecked(false)
            setTimeout(() => setSuccess(false), 5000)
          }
        })
        .catch(err => {
          // console.log("not send", err);
        })
    }
  }
  return (
    <>
      <Helmet>
        <title>Contact | Paramedic</title>
      </Helmet>
      <section id='section_contact_home'>
        <div className='container-fluid'>
          <div className='row' id='home-principal-row'>
            <div className='col-lg-6 left_home'>
              <img
                draggable='false' className='slide-bottom delay_100' src={envelope}
                alt='Illustration groupe de personnes travaillant sur pc'
              />
            </div>
            <div className='col-lg-6 right_home delay_500'>
              <div className='row'>
                <img
                  draggable='false' src={onlyLogoHome} alt='Logo Paramedic Blanc'
                  className='logo_home' data-aos='fade-left' data-aos-duration='1000'
                />
              </div>
              <h1>Nous contacter</h1>
              <img
                draggable='false' src={subWhiteStroke}
                alt='Traits blancs à bouts arrondis' className='subtitle_white_stroke' data-aos='fade-left'
                data-aos-duration='1000' data-aos-delay='500'
              />
              <p data-aos='fade-left' data-aos-duration='1000' data-aos-delay='650'>Vous souhaitez avoir de plus
                amples informations concernant Paramedic ? Un éventuel partenariat ? Rejoindre notre équipe.
              </p>
              <div className='row'>
                <div className='col-md-6'>
                  <Link
                    to='#section_contact_form' data-aos='fade-left' data-aos-duration='1000' data-aos-delay='750'
                    className='head_button'
                  >Nous contacter
                  </Link>
                </div>
                <div className='col-md-6'>
                  <Link
                    to='/embauche' data-aos='fade-left' data-aos-duration='1000' data-aos-delay='750'
                    className='head_button'
                  >Rejoindre notre équipe
                  </Link>
                </div>
              </div>
              <Link to='#section_locaux'>
                <img
                  draggable='false' src={whiteArrow}
                  alt='Flèche blanche orientée vers le bas'
                  className='white_arrow slide-bottom-faster'
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id='section_locaux'>
        <div className='container-fluid locaux-blue'>
          <div className='row'>
            <div className='col-md-6 flb-text-container'>
              <h4>Nos locaux</h4>
            </div>
            <div className='stroke-bottom-responsive' />
            <div className='col-md-6'>
              <p><span>Incubateur Tech Care | Paris &Co</span><br />130, rue de Lourmel, 75015 Paris</p>
            </div>
          </div>
        </div>
        <div className='map'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.879060357363!2d2.2822563159114435!3d48.84144550985337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6700dcfc80ce7%3A0x3d894734748cc5e8!2sTech%20Care%20%7C%20Paris%26Co!5e0!3m2!1sfr!2str!4v1605538583347!5m2!1sfr!2str'
            width='75%' height='auto' frameBorder='0' style={{ border: '0' }} allowFullScreen='' aria-hidden='false'
            tabIndex='0'
          />
        </div>
      </section>

      <section id='section_join'>
        <div className='container'>
          <div className='row join-container'>
            <div className='col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 join-c-i'>
              <img draggable='false' src={joinPhone} alt='Icône téléphone avec ondes' />
            </div>
            <div className='col-8 col-sm-7 col-md-4 col-lg-3 col-xl-3'>
              <h4>Nous joindre par téléphone</h4>
            </div>
            <div className='button-container col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5'>
              <a href='tel:+33186956787'>Ligne de Transports : 01 86 95 67 87</a>
            </div>
          </div>
        </div>
      </section>

      <section id='section_contact_form'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-4'>
              <h3 className='form-title'>Laissez un message</h3>
            </div>
          </div>
          <form onSubmit={sendMsg}>
            <div className='row'>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor='first-name'>Vous êtes ?*</label>
                  <input value={dataForm.nom} onChange={e => handleChange(e)} type='text' name='nom' id='first-name' className={`form-control ${dataFormErr.nom ? 'invalid' : ''}`} />
                </div>
                <div className='form-group'>
                  <label htmlFor='group-name'>Nom de l’établissement / Société de Transport*</label>
                  <input value={dataForm.nomDeLetablissement} onChange={e => handleChange(e)} type='text' name='nomDeLetablissement' className={`form-control ${dataFormErr.nomDeLetablissement ? 'invalid' : ''}`} />
                </div>
                <div className='form-group'>
                  <label>Fonction*</label>
                  <input value={dataForm.fonction} onChange={e => handleChange(e)} type='text' name='fonction' className={`form-control ${dataFormErr.fonction ? 'invalid' : ''}`} />
                </div>
                <div className='form-group'>
                  <label>Numéro de téléphone*</label>
                  <input value={dataForm.telephone} onChange={e => handleChange(e)} type='text' name='telephone' className={`form-control ${dataFormErr.telephone ? 'invalid' : ''}`} />
                </div>
                <div className='form-group'>
                  <label>Adresse éléctronique*</label>
                  <input value={dataForm.email} onChange={e => handleChange(e)} type='email' name='email' className={`form-control ${dataFormErr.email ? 'invalid' : ''}`} />
                </div>
              </div>
              <div id='right-form' className='col-md-4'>
                <div className='form-group'>
                  <label>Message*</label>
                  <textarea value={dataForm.message} onChange={e => handleChange(e)} name='message' className={`form-control-message ${dataFormErr.message ? 'invalid' : ''}`} />
                </div>
                <div className='form-group'>
                  <div className='form-check'>
                    <input onChange={() => setChecked(!checked)} checked={checked} className={`form-check-input ${dataFormErr.checked ? 'ckeckox_invalid' : ''}`} name='validate' type='checkbox' value='' id='invalidCheck' />
                    <label className='form-check-label mentions-checkbox' htmlFor='invalidCheck'>
                      J’accepte les <Link style={{ color: '#2fabe0', textDecoration: 'none' }} to='/mentions-legales'>mentions légales</Link> et la <Link style={{ color: '#2fabe0', textDecoration: 'none' }} to='/confidentialite'>politique de confidentialité</Link>.
                    </label>
                    <div className='invalid-feedback'>
                      Vous devez accepter avant de soumettre le formulaire.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 btn-container'>
                <button type='submit' className='btn-submit'>Soumettre</button>
              </div>
            </div>
            {dataFormErr.checked || dataFormErr.telephone || dataFormErr.nomDeLetablissement || dataFormErr.message || dataFormErr.nom || dataFormErr.email || dataFormErr.fonction
              ? (
                <div style={{ height: '3em', marginTop: '1em' }}>
                  <p className='success' style={{ backgroundColor: 'red' }}>Veuillez remplir les champs indiquer en rouge</p>
                </div>
                )
              : null}
            {success && (
              <div style={{ height: '3em', marginTop: '1em' }}>
                <p className='success'>Votre message a bien été envoyer</p>
              </div>
            )}
          </form>
        </div>
      </section>

    </>
  )
}

export default Contact
